import React from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import SEO from 'components/elements/SEO/SEO'

export default function PrivacyCookiesPage() {
  const { t } = useTranslation()
  return (
    <>
      <SEO title={t('PrivacyCookiesTemplate.PageTitle')} />
      <section className="closer" id="privacy-cookies-policy">
        <h1>
          <Trans>{t('PrivacyCookiesTemplate.title')}</Trans>
        </h1>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p0')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle1')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p1')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle2')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p2')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle3')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p3-1')}</Trans>
        </p>
        <h4>
          <Trans>{t('PrivacyCookiesTemplate.p3-2')}</Trans>
        </h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p3-3')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle4')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p4')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle5')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p5')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle6')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p6')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle7')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p7')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle8')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p8')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle9')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p9-1')}</Trans>
          <a
            href="http://www.garanteprivacy.it/home/provvedimenti-normativa/normativa/normativa-comunitaria-e-intenazionale/trasferimento-dei-dati-verso-paesi-terzi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>{t('PrivacyCookiesTemplate.p9-2')}</Trans>
          </a>
          <Trans>{t('PrivacyCookiesTemplate.p9-3')}</Trans>
          <a
            href="http://eur-lex.europa.eu/legal-content/IT/TXT/?uri=CELEX%3A32016D1250"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Shield
          </a>{' '}
          -{' '}
          <a
            href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/5306161"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>{t('PrivacyCookiesTemplate.p9-4')}</Trans>
          </a>
          <Trans>{t('PrivacyCookiesTemplate.p9-5')}</Trans>
        </p>
        <h3>Cookie</h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p10')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle11')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p11')}</Trans>
          <ul>
            <li>
              Google Chrome{' '}
              <a
                href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies
              </a>
            </li>
            <li>
              Mozilla Firefox{' '}
              <a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
              </a>
            </li>
            <li>
              Microsoft Internet Explorer{' '}
              <a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies
              </a>
            </li>
            <li>
              Apple Safari{' '}
              <a
                href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/
              </a>
            </li>
            <li>
              Opera{' '}
              <a
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://help.opera.com/en/latest/web-preferences/#cookies
              </a>
            </li>
          </ul>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle12')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p12-1')}</Trans>
          <a href="https://policies.google.com/privacy?hl=it" target="_blank" rel="noopener noreferrer">
            <Trans>{t('PrivacyCookiesTemplate.p12-2')}</Trans>
          </a>
          <Trans>{t('PrivacyCookiesTemplate.p12-3')}</Trans>
          <a
            href="https://policies.google.com/technologies/partner-sites?hl=it"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>{t('PrivacyCookiesTemplate.p12-4')}</Trans>
          </a>
          .
        </p>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p12-5')}</Trans>
          <a
            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Analytics Cookie Usage on Websites
          </a>
          .
        </p>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p12-6')}</Trans>
          <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">
            <Trans>{t('PrivacyCookiesTemplate.p12-7')}</Trans>
          </a>{' '}
          (opt-out).
          <Trans>{t('PrivacyCookiesTemplate.p12-8')}</Trans>
          <a
            href="https://support.google.com/youtube/answer/171780?expand=PrivacyEnhancedMode#privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>{t('PrivacyCookiesTemplate.p12-9')}</Trans>
          </a>
          <Trans>{t('PrivacyCookiesTemplate.p12-10')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle13')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p13')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle14')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p14-1')}</Trans>
          <a
            href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>{t('PrivacyCookiesTemplate.p14-2')}</Trans>
          </a>
          );
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle15')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p15-1')}</Trans>
          Via dei Mille, 4 <br />
          +39 346 211 5715 <br />
          80058 Torre Annunziata (NA) <br />
        </p>
        <h4>
          <Trans>{t('PrivacyCookiesTemplate.p15-2')}</Trans>
        </h4>
        <a href="mailto:info@pompeiify.com">info@pompeiify.com</a>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle16')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p16-1')}</Trans>
        </p>
        <h4>
          <Trans>{t('PrivacyCookiesTemplate.p16-2')}</Trans>
        </h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p16-3')}</Trans>
        </p>
        <h4>
          <Trans>{t('PrivacyCookiesTemplate.p16-4')}</Trans>
        </h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p16-5')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle17')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p17-1')}</Trans>
        </p>
        <h4>Stripe (Stripe Inc)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p17-2')}</Trans>
          <a href="https://stripe.com/terms/US" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
        <h4>PayPal (Paypal)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p17-3')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle18')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p18')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle19')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p19')}</Trans>
        </p>
        <h4>Facebook Messenger Customer Chat (Facebook, Inc.)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p20-1')}</Trans>
          <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          <Trans>{t('PrivacyCookiesTemplate.p20-2')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle21')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p21')}</Trans>
        </p>
        <h4>
          <Trans>{t('PrivacyCookiesTemplate.subtitle22')}</Trans>
        </h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p22-1')}</Trans>
          <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          <Trans>{t('PrivacyCookiesTemplate.p22-2')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle23')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p23-1')}</Trans>
        </p>
        <h4>Facebook Audience Network (Facebook, Inc.)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p23-2')}</Trans>
          <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
            <b>
              <Trans>{t('PrivacyCookiesTemplate.p23-3')}</Trans>
            </b>
          </a>
          .
        </p>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p23-4')}</Trans>
          <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">
            <Trans>{t('PrivacyCookiesTemplate.p23-5')}</Trans>
          </a>
          .
        </p>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p23-6')}</Trans>
          <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          -{' '}
          <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">
            Opt out
          </a>
          <Trans>{t('PrivacyCookiesTemplate.p23-7')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle24')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p24-1')}</Trans>
          <a href="http://optout.networkadvertising.org/" target="_blank" rel="noopener noreferrer">
            <Trans>{t('PrivacyCookiesTemplate.p24-2')}</Trans>
          </a>
          .
        </p>
        <h4>AdWords Remarketing (Google Inc.)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p24-3')}</Trans>
          <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          -{' '}
          <a href="https://www.google.com/settings/ads/onweb/optout" target="_blank" rel="noopener noreferrer">
            Opt out
          </a>
          .
        </p>
        <h4>Facebook Remarketing (Facebook, Inc.)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p24-4')}</Trans>
          <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          -{' '}
          <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">
            Opt out
          </a>
          .
        </p>
        <h4>Facebook Custom Audience (Facebook, Inc.)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p24-5')}</Trans>
          <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          -{' '}
          <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">
            Opt out
          </a>
          .
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle25')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p25-1')}</Trans>
        </p>
        <h4>Google Analytics (Google Inc.)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p25-2')}</Trans>
          <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          -{' '}
          <a href="https://www.google.com/settings/ads/onweb/optout" target="_blank" rel="noopener noreferrer">
            Opt out
          </a>
          .
        </p>
        <h4>
          <Trans>{t('PrivacyCookiesTemplate.p25-3')}</Trans>
        </h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p25-4')}</Trans>
        </p>

        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle26')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p26-1')}</Trans>
          <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          <Trans>{t('PrivacyCookiesTemplate.p26-2')}</Trans>
        </p>
        <h3>
          <Trans>{t('PrivacyCookiesTemplate.subtitle27')}</Trans>
        </h3>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p27-1')}</Trans>
        </p>
        <h4>Widget Video YouTube (Google Inc.)</h4>
        <p>
          <Trans>{t('PrivacyCookiesTemplate.p27-2')}</Trans>
          <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          .
        </p>
      </section>
    </>
  )
}
